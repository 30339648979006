.form-wrapper {
  background: $color-light-white;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: $pad-double 0;
  max-width: 650px;
  padding: $pad;
  border-radius: 12px;
}

.support-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 0;
  max-width: 600px;
}

.form-title {
  text-align: center;
  background: $color-green;
  color: white;
  margin: 0;
  padding: 14px;
}

.form-business-title {
  margin: 12px;
  margin-bottom: 0;
  width: 100%;
  text-align: center;
}

.radio-label {
  margin-top: $pad;
}

.form-label--info {
  margin-left: $pad;
}

.form-footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .MuiTypography-body1 {
    text-align: left;
  }
}

.challenge-group {
  display: flex;
  align-items: flex-end;
}

.challenge-label {
  font-weight: 700;
  margin-bottom: 12px;
  text-align: left;
}

.challenge-span {
  min-width: 80px;
  margin-bottom: 8px;
}

@media (max-width: 600px) {

  .dialog {
    padding: 0!important;
  }

}
