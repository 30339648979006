.card-fullWidth {
  width: 100%;
}

.card-pledge {
  list-style: none;
  padding: 0!important;

  &:last-child {
    .MuiDivider-root {
      display: none;
    }
  }
}
.amount .MuiTypography-body1{
  color: $color-green;
  font-weight: 700;
}

.card-business-title {
  margin: 0;
}

.CardBusiness-whiteIcon-214 {
  transition: color 200ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    color: $color-red;
    transition: color 200ms cubic-bezier(0.4, 0, 0.2, 1);
  }
}
.card-media {
    flex-grow: 1;
    position: relative;

  @media (max-width: 960px) {
    height: 110px;
    width: 100%;
  }
}

.home-card .MuiDivider-root {
  margin: $pad-double 0;
}

.challenge-complete {
  position: absolute;
  right: -16px;
  top: -13px;
  z-index: 2;
}

.card-header-amount {
  text-align: right;
  position: relative;
}

.icon-wrapper {
  margin-top: 2px;
  margin-left: -7px;

  a:hover {
    .biz-icon {
      color: $color-red;
      transition: color 80ms cubic-bezier(0.4, 0, 0.2, 1) 0ms
    }
  }

  .biz-icon {
    color: white;
    transition: color 80ms cubic-bezier(0.4, 0, 0.2, 1) 0ms
  }
}


@media (max-width: 960px) {
  .card-header-amount {
    text-align: left;
  }
  .pledge-icon {
    display: none!important;
  }
  .challenge-complete {
    display: none;
  }
}
