.button-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding-bottom: $pad-double;
  max-width: 400px;
  margin: auto;
}

.button {
  margin: $pad 0;
  padding: $pad $pad-double;
  border-radius: 2px;
  background-color: $color-yellow;
  color: $color-green;
  font-weight: 500;
  font-size: 14px;
  text-decoration: none;

  &.block {
    width: 100%;
  }

  &.button-secondary {
    background-color: $color-light-white;
    color: $color-green;
  }
}
.submit-button {
  margin-top: $pad;
}
