.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-logo {
  height: 15vmin;
  pointer-events: none;
}

.App-lockup {
  height: 20vmin;
  margin: $pad 0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 7s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: $pad;
  max-width: 900px;
  color: white;
  margin: auto;

  h1 {
    margin: 0;
  }

  .sub-header {
    font-size: 28px;
    margin-bottom: 12px;
    text-transform: uppercase;
  }

  >img {
    width: 90px;
  }
}

.four-oh-four .App-header {
  color: $color-green;
}

.Footer {
  a {
    color: white;
  }
}
