.Home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  min-width: 100vw;

.home-content {
  padding: $pad-triple;
  width: 100%;

}

.home-howto {
  h5 {
    margin-bottom: 0;
  }
}

.list-icon {
  border-radius: 50%;
  width: 50px;
  font-size: 23px;
  border: 1px solid $color-red;
  color: $color-red;
  padding: 4px 12px;
}

.secondary-subheader {
  margin: $pad-half;
}

.goal-bar {
  display: flex;
  flex-direction: column;
}

.goal-list li {
  font-size: 18px;
  margin-bottom: $pad;
}
}
